.footer {
  min-height: 200px;
  /* background-color: rgb(22, 22, 22); */
  color: white;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 30px;
  background: rgb(22, 22, 22);
  background-image: radial-gradient(rgb(16, 16, 16) 1px, transparent 0);
  background-size: 30px 30px;
}

#footer-img {
  max-width: 100%;
  opacity: 0.8;
}

ul {
  margin-top: 30px;
  text-align: left;
  list-style-type: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
}

#footer-nav {
  margin-top: 40px;
  margin-left: 100px;
  border-left: solid 1px rgb(67, 67, 67);
}

#footer-nav-cont {
  line-height: 200px;
  display: table;
}

.footer-text {
  padding-top: 10px;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  opacity: 0.3;
  line-height: 2;
}

li a {
  opacity: 0.8;
  color: white;
}

#footer-name {
  margin-top: 15px;
  opacity: 0.8;
  color: white;
  font-size: 70px;
  font-weight: 600;
}

.social-media-icons {
  margin-top: 70px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-self: flex-end;
}

.social-media-icons a {
  color: white;
  margin: 20px;
  transition: 0.2s;
  opacity: 0.7;
}

.social-media-icons a:hover {
  opacity: 1;
  color: #f50057;
  transition: 0.2s;
}

#footer-quote {
  opacity: 0.3;
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}

@media (max-width:960px) {
  #footer-name {
    font-size: 50px;
  }

  .social-media-icons {
    margin: 0;
  }

  #footer-nav {
    margin: 0;
  }
}

@media (max-width:600px) {
  .social-media-icons {
    padding-bottom: 20px !important;
  }

  .social-media-icons a {
    margin: 7px;
  }

  #footer-name {
    margin: 0;
  }

  #footer-quote {
    margin: 0;
    padding: 0;
    padding-top: 10px;
  }
}

@media (max-width:960px) {
  #footer-img{    
    display: none !important;
  }
}