@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
  font-family: 'Poppins', sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

p {
  font-size: 16px;
}

html, body {
  /* background: rgb(253, 253, 253); */
  background: white;
  background-image: radial-gradient(rgb(200, 200, 200) 1px, transparent 0);
  background-size: 30px 30px;
}