.nav-link {
  height: 100px;
  margin-top: 10px;
  margin-right: 100px;
  font-size: 16px;
  color: #595959;
  font-weight: 300;
}

.nav-link::after {
  display:block;
  content: '';
  border-bottom: solid 3px rgb(38 38 38);  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

.nav-link:not(#resume-btn, .wrapper .nav-link):hover:after, .nav-link.active:not(#resume-btn)::after { 
  transform: scaleX(1); 
}

.nav-link:hover, .nav-link.active { 
  color: rgb(38 38 38);  
}

.navbar {
  position: 'fixed'
}

#nav {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,0) 100%);
}

@media (max-width: 900px) {
  #nav {
    display: none;
  }

  #collapsed-navbar {
    display: block !important;
  }
}

#collapsed-navbar {
  display: none;
}

.wrapper {
  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  clip-path: circle(25px at calc(100% - 45px) 45px);
  transition: all 0.3s ease-in-out;
}

#active:checked ~ .wrapper {
  clip-path: circle(75%);
}

.menu-btn {
  position: fixed;
  z-index: 200;
  right: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: black;
  cursor: pointer;
  background: white;
  transition: all 0.3s ease-in-out;
}

#active:checked ~ .menu-btn {
  background: #fff;
}

#close-nav {
  display: none;
}

#active:checked ~ .menu-btn #open-nav {
  display: none;
}

#active:checked ~ .menu-btn #close-nav {
  display: inline-block ;
  animation: rotateIcon 0.7s
}

#open-nav {
  animation: rotateIcon2 0.7s;
}

@keyframes rotateIcon {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes rotateIcon2 {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(-360deg);}
}

.wrapper ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}

.wrapper ul li {
  margin: 15px 0;
}

.wrapper ul li a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: black;
  position: relative;
  transition: all 0.3s ease;
}

.wrapper ul li a:hover {
  color: #3f51b5;
}

input[type="checkbox"] {
  display: none;
}

#collapsed-navbar .nav-link {
  margin: 0;
  padding: 0 !important;
  height: auto;
}

.wrapper ul {
  padding: 0;
}
