@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@500;700&family=Orelega+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  font-family: 'Poppins', sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

p {
  font-size: 16px;
}

html, body {
  /* background: rgb(253, 253, 253); */
  background: white;
  background-image: radial-gradient(rgb(200, 200, 200) 1px, transparent 0);
  background-size: 30px 30px;
}
#home-img {  
  padding-top: 50px;
  width: 120%;
}

#section-1, #skills, #about, #projects, #contact {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

#section-1 {
  height: 100vh;
  text-align: left;
}

#skills, #about, #projects, #contact, #learning {
  padding-top: 70px !important;
}

.intro-text {
  height: 100%;
  float: left;
  padding-top: 30%;
  padding-left: 100px;
}

#name {
  font-size: 70px;
  font-weight: 700;
}

#text {
  font-size: 30px;
}

#skills {
  padding-bottom: 70px;
  text-align: center;
}

.card-skills, .card-tools {
  width: 50%;
} 

.ant-card-head-title {
  font-size: 30px;
}

.skill-icons, .tool-icons {
  justify-content: center;
  text-align: center;
}

.adobe-icon {
  margin-right: 15px;
}

.cando-card {
  text-align: center;
  justify-content: center;
  min-height: 200px;   
}

#back-top-btn {
  color: rgb(100, 100, 100);
  background-color: rgb(184, 184, 184);
  border-radius: 50px;
  padding: 7px;
  opacity: 0.7;
  transition: linear 0.1s;
}

#back-top-btn:hover {
  opacity: 1;
  transition: linear 0.1s;
  transform: translateY(-5px); 
  box-shadow: 3px 3px 15px 3px rgba(0,0,0,0.05);
}

#know-me-btn {
  margin-right: 20px;
}

#icon-arrow {
  transition: linear 0.2s;
}

.link-btn:hover [id="icon-arrow"] {
  transform: rotate(90deg);
  transition: linear 0.2s;
}

#experience, #education {
  margin-left: 5%;
  margin-right: 5%;
}

#job-paper, #edu-paper {
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1.5;
}

#jacobs-logo, #emis-logo, #bmw-logo, #idmc-logo, #jsc-logo {
  width: 100%;
  padding: 5px;
}

.weblink svg {
  transform: translateY(-1.5px);
}

.weblink {
  color: rgb(68, 68, 68);
}

/* #projects {
  padding-left: 10%;
  padding-right: 20%;
  padding-top: 0px;
} */

.project-card, .cando-card {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

#about-text {
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 20px;
}

h1, #skills h1, #about h1, #projects h1, h2 {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 700;
}

h3 {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 500;
}

#software:hover, #artist:hover {
  text-decoration: underline;
}

.icons svg, .icons2 svg {
  margin: 10px;
}

.icons:hover {
  cursor: pointer;
}

.just-icons {
  animation: cssAnimation 0s 0.5s forwards, fadeIn 1.5s;
  visibility: hidden;
}

@keyframes cssAnimation {
  to { visibility: visible; }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.ant-progress-bg {
  animation: expand 1.5s;;
}

.ant-progress-bg, .ant-progress-inner {
  transform: scaleY(0.8);
}

@keyframes expand {
  0% {width:0;}
  100% {width:1;}
}

.skills-progress {
  padding-right: 15px;
  padding-left: 15px;
}

.MuiGrid-spacing-xs-5 > .MuiGrid-item {
  text-align: left;
  font-size: 13px;
}

@media (max-width:900px) {
  #disappear {
    display: none;
  }

  #about-text {
    padding: 0px;
    font-size: 14px;
  }

  .time {
    display: block !important;
  }
}

@media (max-width:960px) {
  #home-img {
    padding: 0px;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
  }

  #name {
    font-size: 54px;
  }

  #text {
    font-size: 18px;
  }

  .intro-text {
    padding: 0px;
  }

  #section-1 {
    height: 100vh;
  }

  #skills, #about, #projects, #contact {
    padding-left: 10;
    padding-right: 10;
  }
  
  #section-1 {
    height: 100% !important;
  }
}

.project-card:hover {
  z-index: 80;
  transform: translateY(-5px);
  transition: transform linear 0.2s;
}

.project-card {
  max-width: 100%;
  text-align: center;
}

@media (max-width:600px) {
  p:not(#text) {
    font-size: 14px !important;
  }

  h5 {
    font-size: 18px !important;
  }

  .MuiTimeline-root {
    padding: 0 !important;
  }

  #projects {
    padding:10% !important;
  }

  #home-img {
    padding-top: 20px;
  }

  #skills {
    padding-top: 150px !important;
  }
}

@media (max-width:361px) {
  .skills-progress {
    padding: 0 35px !important;
  }

  #about, #projects, #contact  {
    padding: 0;
  }

  #about-text {
    padding: 20px !important;
    font-size: 14px !important;
  } 

  .MuiGrid-spacing-xs-8 > .MuiGrid-item {
    padding: 0 !important;
  }

  #projects {
    padding: 20px !important;
    padding-bottom: 50px !important;
  }
}

@media (max-width:390px) {
  .skills-progress {
    padding: 0 20px !important;
  }
}

#ui-ux-expand-arrow:hover, #frontend-expand-arrow:hover, #backend-expand-arrow:hover {
  opacity: 1;
  animation: expandArrow 1s infinite;
}

@keyframes expandArrow {
  0% {transform: translateY(0px);}
  50% {transform: translateY(5px);}
  100% {transform: translateY(0px);}
}

#ui-ux-expand-arrow {
  opacity: 0.8;
  color: #3f51b5;
  transition: linear 0.5s;
}

#frontend-expand-arrow {
  opacity: 0.8;
  color: #f50057;
  transition: linear 0.5s;
}

#backend-expand-arrow {
  opacity: 0.8;
  transition: linear 0.5s;
}

.cando-card:hover [id="ui-ux-icons"] {
  color: #3f51b5;
  transition: linear 0.5s;
}

#ui-ux-icons {
  transition: linear 0.3s;
}

.cando-card:hover [id="frontend-icons"] {
  color: #f50057;
  transition: linear 0.5s;
}

#frontend-icons {
  transition: linear 0.3s;
}

#ui-ux-skills svg {
  color: #3f51b5;
  /* transform: scale(0.8); */
}

#frontend-skills svg {
  color: #f50057;
}

#fname, #lname, #subject, #email, #message {
  border-color: #f50057;
}

/* #fname::placeholder, #lname::placeholder, #subject::placeholder, #email::placeholder, #message::placeholder {
  color: #f50057;
  opacity: 0.7;
  font-weight: 400;
} */

#contact-form-cont {
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 70px;
}

#message {
  height: 144px;
}

#submit-btn {
  border-color: #3f51b5;
  color: #3f51b5;
}

#submit-btn:hover {
  color: white;
  background-color: #3f51b5;
}

@media (max-width:600px) {
  #contact-form-cont {
    padding: 0 !important;
  }

  #timeline-content {
    width: 100% !important;
    margin: 0;
  }

  #about {
    margin: 0;
    padding: 1%;
  }

  #about-text {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.time {
  text-align: center;
  font-size: 13px;
  display: none;
  background-color: #3f51b5;
  opacity: 0.7;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 10px;
  padding-bottom: 10px;
  transform: translateY(15px);
  border-radius: 4px;
}

#job-paper, #edu-paper {
  cursor: pointer;
}

#video-link {
  transform: translateY(-1px);
}

#contact-form-cont {
  z-index: 510;
}
.nav-link {
  height: 100px;
  margin-top: 10px;
  margin-right: 100px;
  font-size: 16px;
  color: #595959;
  font-weight: 300;
}

.nav-link::after {
  display:block;
  content: '';
  border-bottom: solid 3px rgb(38 38 38);  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

.nav-link:not(#resume-btn, .wrapper .nav-link):hover:after, .nav-link.active:not(#resume-btn)::after { 
  transform: scaleX(1); 
}

.nav-link:hover, .nav-link.active { 
  color: rgb(38 38 38);  
}

.navbar {
  position: 'fixed'
}

#nav {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,0) 100%);
}

@media (max-width: 900px) {
  #nav {
    display: none;
  }

  #collapsed-navbar {
    display: block !important;
  }
}

#collapsed-navbar {
  display: none;
}

.wrapper {
  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  -webkit-clip-path: circle(25px at calc(100% - 45px) 45px);
          clip-path: circle(25px at calc(100% - 45px) 45px);
  transition: all 0.3s ease-in-out;
}

#active:checked ~ .wrapper {
  -webkit-clip-path: circle(75%);
          clip-path: circle(75%);
}

.menu-btn {
  position: fixed;
  z-index: 200;
  right: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: black;
  cursor: pointer;
  background: white;
  transition: all 0.3s ease-in-out;
}

#active:checked ~ .menu-btn {
  background: #fff;
}

#close-nav {
  display: none;
}

#active:checked ~ .menu-btn #open-nav {
  display: none;
}

#active:checked ~ .menu-btn #close-nav {
  display: inline-block ;
  animation: rotateIcon 0.7s
}

#open-nav {
  animation: rotateIcon2 0.7s;
}

@keyframes rotateIcon {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes rotateIcon2 {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(-360deg);}
}

.wrapper ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}

.wrapper ul li {
  margin: 15px 0;
}

.wrapper ul li a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: black;
  position: relative;
  transition: all 0.3s ease;
}

.wrapper ul li a:hover {
  color: #3f51b5;
}

input[type="checkbox"] {
  display: none;
}

#collapsed-navbar .nav-link {
  margin: 0;
  padding: 0 !important;
  height: auto;
}

.wrapper ul {
  padding: 0;
}

.footer {
  min-height: 200px;
  /* background-color: rgb(22, 22, 22); */
  color: white;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 30px;
  background: rgb(22, 22, 22);
  background-image: radial-gradient(rgb(16, 16, 16) 1px, transparent 0);
  background-size: 30px 30px;
}

#footer-img {
  max-width: 100%;
  opacity: 0.8;
}

ul {
  margin-top: 30px;
  text-align: left;
  list-style-type: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
}

#footer-nav {
  margin-top: 40px;
  margin-left: 100px;
  border-left: solid 1px rgb(67, 67, 67);
}

#footer-nav-cont {
  line-height: 200px;
  display: table;
}

.footer-text {
  padding-top: 10px;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  opacity: 0.3;
  line-height: 2;
}

li a {
  opacity: 0.8;
  color: white;
}

#footer-name {
  margin-top: 15px;
  opacity: 0.8;
  color: white;
  font-size: 70px;
  font-weight: 600;
}

.social-media-icons {
  margin-top: 70px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-self: flex-end;
}

.social-media-icons a {
  color: white;
  margin: 20px;
  transition: 0.2s;
  opacity: 0.7;
}

.social-media-icons a:hover {
  opacity: 1;
  color: #f50057;
  transition: 0.2s;
}

#footer-quote {
  opacity: 0.3;
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}

@media (max-width:960px) {
  #footer-name {
    font-size: 50px;
  }

  .social-media-icons {
    margin: 0;
  }

  #footer-nav {
    margin: 0;
  }
}

@media (max-width:600px) {
  .social-media-icons {
    padding-bottom: 20px !important;
  }

  .social-media-icons a {
    margin: 7px;
  }

  #footer-name {
    margin: 0;
  }

  #footer-quote {
    margin: 0;
    padding: 0;
    padding-top: 10px;
  }
}

@media (max-width:960px) {
  #footer-img{    
    display: none !important;
  }
}
